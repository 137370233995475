@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	background: #FAFAFA;
	font-family: 'Helvetica Neue', arial, sans-serif;
	font-weight: 400;
	color: #444;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	box-sizing: border-box;
}

#app {
	height: 100%;
}

/* Stripe Styling */
 .section {
	background: #ffffff;
	display: flex;
	flex-direction: column;
	width: 400px;
	height: 112px;
	border-radius: 6px;
	justify-content: space-between;
}

.product {
	display: flex;
	flex: 1;
}

.description {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.p--stripe {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: -0.154px;
	color: #242d60;
	height: 100%;
	width: 100%;
	padding: 0 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
}

.svg--stripe {
	border-radius: 6px;
	margin: 10px;
	width: 54px;
	height: 57px;
}

.h3--stripe, .h5--stripe {
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: -0.154px;
	color: #242d60;
	margin: 0;
}

.h5--stripe {
	opacity: 0.5;
}

#checkout-and-portal-button {
	height: 36px;
	background: #556cd6;
	color: white;
	width: 100%;
	font-size: 14px;
	border: 0;
	font-weight: 500;
	cursor: pointer;
	letter-spacing: 0.6;
	border-radius: 0 0 6px 6px;
	transition: all 0.2s ease;
	box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
}

#checkout-and-portal-button:hover {
	opacity: 0.8;
}